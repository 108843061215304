export const animateValue = (
  element: HTMLElement,
  start: number,
  end: number,
  duration: number,
  isCurrency: boolean
) => {
  const obj = element;
  const range = end - start;
  const minTimer = 50;

  let stepTime = Math.abs(Math.floor(duration / range));

  stepTime = Math.max(stepTime, minTimer);

  const startTime = new Date().getTime();
  const endTime = startTime + duration;
  let timer;

  const run = () => {
    const now = new Date().getTime();
    const remaining = Math.max((endTime - now) / duration, 0);
    let value;

    isCurrency
      ? ((value = end - remaining * range),
        (obj.innerHTML = value.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })))
      : ((value = Math.round(end - remaining * range)),
        (obj.innerHTML = String(value)));

    if (Number(value) == end) {
      clearInterval(timer);
    }
  };

  timer = setInterval(run, stepTime);
  run();


};
export function convertNumber(number: any) {
  if (!isNaN(Number(number))) {
    return Number(number);
  } else {
    return Number(number);
  };
}
