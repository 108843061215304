import { Component, OnInit } from '@angular/core';
import { AuthService } from 'shared/services/auth.service';
import { MenuItemsService } from 'shared/services/menu.service';
//import { PermissionsService } from 'shared/services/permissions.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  panelOpenState = false;
  customCollapsedHeight = '48px';
  customExpandedHeight = '48px';
  collapsed = false;
  isExpanded = true;
  collapsedClass = '';
  icon = '';

  //idUser = sessionStorage['userId'];
  menuItems = [];
  userPermissions = [];
  constructor(
    public menu: MenuItemsService,
    public authService: AuthService, //, private permissionService: PermissionsService
  ) {}

  ngOnInit() {
    this.loadPermissionsMenu();
    this.toggleExpanded();
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
    this.icon = this.isExpanded ? 'fa fa-arrow-right' : 'fa fa-arrow-left';

    if (this.isExpanded) {
      this.collapsedClass = 'collapsed';
    } else {
      this.collapsedClass = '';
    }
  }

  loadPermissionsMenu() {
    this.authService.getPermissionByUserId(sessionStorage['userId']).subscribe({
      next: (data: any) => {
        if (data) {
          this.userPermissions = data;
          this.menu.navigation.forEach((element) => {
            var itemMenu = this.userPermissions.filter(function (p) {
              return p.siglaFuncionalidade == element.permission;
            });
            if (itemMenu.length > 0) this.menuItems.push(element);
            // {
            //     if (element.children)
            //     {
            //       element.children.forEach((child) => {
            //           this.permissionService.getCheckPermissions(this.idUser, child.permission).subscribe({
            //             next: (permission: any) => {
            //               if (permission === null) {
            //                 delete element.children[child.title];
            //               }
            //             },
            //           })
            //       });
            //   }
            //   this.menuItems.push(element);
            // }
          });
        }
      },
    });
  }
}
