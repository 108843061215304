<div class="chart-container">
  <canvas
    baseChart
    [options]="lineChartOptions"
    [datasets]="lineChartData"
    [labels]="lineChartLabelsX"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [colors]="lineChartColors"
  ></canvas>
</div>
