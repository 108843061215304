<header>
  <div class="header">
    <div class="responsive-menu">
      <a (click)="openResponsiveMenu()">
        <mat-icon>menu</mat-icon>
      </a>
    </div>
    <div class="logo-side">
      <a href="#/dashboard">
        <img
          class="logo"
          src="assets/images/logo-OITO.png"
          alt="OITO Crédito Imobiliário"
        />
      </a>
    </div>
    <div class="user-side">
      <div class="user-side--content">
        <button mat-button [matMenuTriggerFor]="menuUser">
          <div class="menu-user">
            <div class="photo">
              <ngx-avatar
                *ngIf="!user?.photo"
                size="45"
                [round]="false"
                name="{{ onlyFirstName(user?.name) }}"
              ></ngx-avatar>
            </div>
            <span class="name">{{ onlyFirstName(user?.name) }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #menuUser="matMenu" xPosition="before">
          <button
            (click)="openDialog()"
            class="user-content-button"
            mat-menu-item
          >
            <mat-icon>account_circle</mat-icon>
            Perfil
          </button>
          <button class="user-content-button" (click)="logout()" mat-menu-item>
            <mat-icon>arrow_back</mat-icon>
            Sair
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>

<div class="responsive-menu-content">
  <ul class="navigation">
    <li *ngFor="let menu of menuItems; index as i">
      <ng-container *ngIf="!menu.children">
        <a
          [routerLink]="menu.url"
          routerLinkActive="active"
          style="display: flex"
        >
          <mat-icon class="mr-2">{{ menu.icon }}</mat-icon>
          <span> {{ menu.title }}</span>
        </a>
      </ng-container>
      <ng-container *ngIf="menu.children">
        <mat-accordion class="sidebar-accordion">
          <mat-expansion-panel>
            <mat-expansion-panel-header
              [collapsedHeight]="customCollapsedHeight"
              [expandedHeight]="customExpandedHeight"
            >
              <mat-panel-title>
                <mat-icon class="mr-2">{{ menu.icon }}</mat-icon>
                <span>{{ menu.title }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a
              *ngFor="let child of menu.children"
              class="sidebar-child"
              [routerLink]="child.url"
              routerLinkActive="active"
            >
              <div class="menu-icone mr-2">
                <i [class]="child.icon"></i>
              </div>
              <span>{{ child.title }}</span>
            </a>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </li>
  </ul>
</div>
