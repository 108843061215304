<div class="totals-card">
  <div class="totals-card__body">
    <i *ngIf="classIcon" [class]="classIcon + ' icon'" [ngStyle]="iconRotate && {'transform': 'rotate(-30deg)'}"></i>
    <div class="totals-card__body--header">{{ title }}</div>
    <div class="totals-card__body--content">
      <span #totalElement [hidden]="!total">
        {{ isCurrency ? (total | currency: 'BRL') : total }}
      </span>
    </div>
  </div>
</div>
