import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from 'shared/utils/base-form.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'shared/services/auth.service';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
})
export class ProfileModalComponent extends BaseFormComponent implements OnInit {
  user: any;
  url: string;
  senha: string;
  userId: number;
  mostraSenha: boolean = false;

  public userForm: UntypedFormGroup = new UntypedFormGroup({
    nome: new UntypedFormControl(),
    email: new UntypedFormControl(),
    telefone: new UntypedFormControl(),
    senhaAtual: new UntypedFormControl(),
    senhaNova: new UntypedFormControl(),
    senhaConfirmacao: new UntypedFormControl(),
  });

  get nome() {
    return this.userForm.get('nome').value;
  }
  get email() {
    return this.userForm.get('email').value;
  }
  get telefone() {
    return this.userForm.get('telefone').value;
  }
  get senhaAtual() {
    return this.userForm.get('senhaAtual').value;
  }
  get senhaNova() {
    return this.userForm.get('senhaNova').value;
  }
  get senhaConfirmacao() {
    return this.userForm.get('senhaConfirmacao').value;
  }

  constructor(
    public authService: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<ProfileModalComponent>,
    snackBar: MatSnackBar,
  ) {
    super(snackBar);
    this.user = {
      id: 0,
      nome: '',
      email: '',
      telefone: '',
      senha: '',
      senhaNova: '',
      senhaAtual: '',
      senhaConfirmacao: '',
    };
  }

  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo() {
    this.authService.getById(sessionStorage['userId']).subscribe({
      next: (data: any) => {
        if (data) {
          this.user = data;
          this.senha = data.senha;
          this.userId = data.id;
        }
      },
    });
  }

  mostraSenhas() {
    this.mostraSenha = !this.mostraSenha;
  }

  changeProfilePhoto(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = () => {
        //this.url = event.target.result;
      };
    }
  }

  onlyFirstName(name) {
    return name.replace(/ .*/, '');
  }

  validarSenha() {
    if (this.mostraSenha) {
      //todas preenchidas
      if (
        this.user.senhaAtual == '' ||
        this.user.senhaAtual === undefined ||
        this.user.senhaNova == '' ||
        this.user.senhaNova === undefined ||
        this.user.senhaConfirmacao == '' ||
        this.user.senhaConfirmacao === undefined
      ) {
        this.openSnackBar(
          'Todas as senhas devem estar preenchidas!',
          'OK',
          'danger-snackbar',
        );
        return false;
      }
      //senha nova != confirmacao
      else if (this.user.senhaConfirmacao != this.user.senhaNova) {
        this.openSnackBar(
          'Novas senha e confirmação são diferentes!',
          'OK',
          'danger-snackbar',
        );
        return false;
      }
      //senha atual != senha do banco
      else if (this.user.senhaAtual != this.senha) {
        this.openSnackBar(
          'Senha atual diferente da cadastrada!',
          'OK',
          'danger-snackbar',
        );
        return false;
      } else return true;
    }
    return true;
  }

  salvar() {
    if (this.validarSenha()) {
      if (this.user.nome !== '' && this.user.email !== '') {
        var obj = {
          id: this.userId,
          nome: this.user.nome,
          email: this.email,
          telefone: this.telefone,
          senha: this.user.senhaNova,
        };

        this.authService.updateUser(obj).subscribe({
          next: () => {
            this.openSnackBar(
              'Dados do usuário alterados com sucesso!',
              'OK',
              'success-snackbar',
            );
            sessionStorage['nome'] = this.user.nome;
            this.dialogRef.close();
          },
          error: () => {
            this.openSnackBar(
              'Erro ao alterar os dados do usuário. Tente novamente!',
              'OK',
              'danger-snackbar',
            );
          },
        });
      }
    }
  }
}
