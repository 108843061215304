import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { animateValue } from 'shared/helpers/number-animation';

@Component({
  selector: 'app-totals-card',
  templateUrl: './totals-card.component.html',
  styleUrls: ['./totals-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TotalsCardComponent implements OnInit {
  @ViewChild('totalElement', { static: true }) totalElement: ElementRef;
  @Input() classIcon: string;
  @Input() iconRotate = false;
  @Input() title: string;
  @Input() isCurrency = false;
  @Input() total: number;
  @Input() isAnimated = false;
  @Input() startAnimation = 0;
  @Input() animationDuration = 3000;

  constructor() {}

  ngOnInit() {
    this.isAnimated &&
      animateValue(
        this.totalElement.nativeElement,
        this.startAnimation,
        this.total,
        this.animationDuration,
        this.isCurrency,
      );
  }
}
