import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CheckAuthComponent } from 'shared/components/check-auth/check-auth.component';
import { AppBlankComponent } from './core/app-blank/app-blank.component';
import { AppDefaultComponent } from './core/app-default/app-default.component';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: CheckAuthComponent,
  },
  {
    component: AppBlankComponent,
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    component: AppDefaultComponent,
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'consultas',
    loadChildren: () => import('./modules/consults/consults.module').then((m) => m.ConsultsModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'cadastros',
    loadChildren: () => import('./modules/records/records.module').then((m) => m.RecordsModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'crm',
    loadChildren: () => import('./modules/crm/crm.module').then((m) => m.CrmModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'treinamento-corretores',
    loadChildren: () =>
      import('./modules/brokers-training/brokers-training.module').then(
        (m) => m.BrokersTrainigModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'copa-oito',
    loadChildren: () =>
      import('./modules/edition-copa8/edition-copa8.module').then((m) => m.EditionCopa8Module),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FAQModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'processos',
    loadChildren: () =>
      import('./modules/processes/processes.module').then((m) => m.ProcessesModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'sem-permissao',
    loadChildren: () =>
      import('./modules/without-permission/without-permission.module').then(
        (m) => m.WithoutPermissionModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'leads',
    loadChildren: () => import('./modules/leads/leads.module').then((m) => m.LeadsModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'leads_invest',
    loadChildren: () =>
      import('./modules/leads copy/leads.module').then((m) => m.CRMOitoInvestModule),
    canActivate: [AuthGuard],
  },
  {
    component: AppDefaultComponent,
    path: 'clube-oito',
    loadChildren: () =>
      import('./modules/club-oito/club-oito.module').then((m) => m.ClubOitoModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
