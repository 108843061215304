import { Injectable } from '@angular/core';
import { MenuInterface } from 'shared/interfaces/menu.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  public navigation: MenuInterface[] = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'today',
      permission: 'dashboard',
    },
    // {
    //   title: 'Cadastros',
    //   url: '',
    //   icon: 'filter_none',
    //   permission: 'cadastros',
    //   children: [
    //     {
    //       title: 'Dados Dashboard',
    //       url: '/cadastros/dados-dashboard',
    //       icon: 'fa fa-table',
    //       permission: 'cadastros.dadosDashboard',
    //     },
    //   ],
    // },
    {
      title: 'Consultas',
      url: '',
      icon: 'search',
      permission: 'consultas',
      children: [
        {
          title: 'Dashboard - BD OITO',
          url: '/consultas/dashboard',
          icon: 'fa fa-tachometer',
          permission: 'consultas.dashboard',
        },
        {
          title: 'Propostas - BD OITO',
          url: '/consultas/propostas-bdoito',
          icon: 'fa fa-commenting-o',
          permission: 'consultas.conformidadeCaixa',
        },
      ],
    },
    {
      title: 'CRM',
      url: '',
      icon: 'people',
      permission: 'crm',
      children: [
        {
          title: 'Imobiliárias Parceiras',
          url: '/crm/imobiliarias-parceiras',
          icon: 'fa fa-building-o',
          permission: 'crm.imobiliariasParceiras',
        },
        {
          title: 'Corretores',
          url: '/crm/corretores',
          icon: 'fa fa-id-badge',
          permission: 'crm.corretores',
        },
      ],
    },
    {
      title: 'Treinamento Corretores',
      url: '',
      icon: 'swap_vertical_circle',
      permission: 'extracoes',
      children: [
        {
          title: 'Treinamento',
          url: '/treinamento-corretores/treinamentos',
          icon: 'fa fa-certificate',
          permission: 'extracoes.certificadosOito',
        },
      ],
    },
    {
      title: 'Leads',
      url: '/leads',
      icon: 'filter_alt',
      permission: 'leads.leads',
    },
    // {
    //   title: 'Copa Oito',
    //   url: '',
    //   icon: 'folder_shared',
    //   permission: 'copaoito',
    //   children: [
    //     {
    //       title: 'Inscrições',
    //       url: '/copa-oito/inscricoes',
    //       icon: 'fa fa-futbol-o',
    //       permission: 'copaoito.inscricoes',
    //     },
    //   ],
    // },
    {
      title: 'Comunicação Gestores',
      url: '',
      icon: 'mail',
      permission: 'faq',
      children: [
        {
          title: 'Cadastro',
          url: '/faq/cadastro',
          icon: 'fa fa-pencil-square-o',
          permission: 'faq.cadastro',
        },
        {
          title: 'FAQ/Comunicados',
          url: '/faq/comunicados',
          icon: 'fa fa-clipboard',
          permission: 'faq.consulta',
        },
      ],
    },
    {
      title: 'Processos',
      url: '',
      icon: 'space_dashboard',
      permission: 'processos',
      children: [
        {
          title: 'Acompanhamento de Processos',
          url: '/processos/processos-details',
          icon: 'fa fa-envelope',
          permission: 'processos.consulta',
        },
        {
          title: 'Análise de Crédito Automática',
          url: '/processos/processos-credito',
          icon: 'fa fa-plus',
          permission: 'processos.analiseAutomatica',
        },
      ],
    },
    {
      title: 'CRM Oito Invest',
      url: '',
      icon: 'local_atm',
      permission: 'oitoinvest',
      children: [
        {
          title: 'Leads',
          url: '/leads_invest',
          icon: 'fa fa-odnoklassniki',
          permission: 'oitoinvest.leads',
        },
      ],
    },
    {
      title: 'Clube Oito',
      url: '',
      icon: 'group2',
      permission: 'extracoes.certificadosOito',
      children: [
        {
          title: 'Controle Clube Oito',
          url: '/clube-oito/controle',
          icon: 'fa fa-male',
          permission: 'extracoes.certificadosOito',
        },
      ],
    },
  ];
}
