import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss'],
})
export class ModalConfirmationComponent {
  title = 'Alerta!';
  textCancel = 'Cancelar';
  textOk = 'OK';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private confirmDialog: MatDialogRef<any>) {
    this.title = data.title || this.title;
    this.textCancel = data.textCancel || this.textCancel;
    this.textOk = data.textOk || this.textOk;
  }

  confirm() {
    this.confirmDialog.close(true);
  }
}
