// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  language: 'pt-BR',
  production: true,
  //api: 'http://localhost:65016/',
  api: 'https://apiportalinterno.oitocreditoimobiliario.com.br/',
  recaptchaKey: '6LcOuyYTAAAAAHTjFuqhA52fmfJ_j5iFk5PsfXaU',
};

export const API = {
  AGREEMENT_CAIXA: 'conformidadeCaixa',
  AUTH: '.auth',
  BROKER_TRAINING: 'treinamento',
  DASHBOARD: 'dashboard',
  CRM: 'crm',
  TRAINING_CERTIFICATE: 'certificadoOito',
  USER: 'usuario',
  USER_PROFILE: 'perfilUsuario',
  FAQ: 'faq',
  PORTAL_REPASSE: 'portalRepasse',
  LEAD: 'lead',
  INTEGRACAO_TIIB: 'integracaoTiib',
  OITO_INVEST: 'leadOitoInvest',
  TRASFER_PORTAL: 'portalRepasse',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
