import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mat-error[app-form-field-error]',
  template: `{{ errorMessage }}`,
})
export class FormFieldErrorComponent {
  @Input() ctrl: UntypedFormControl;

  constructor() {}

  public get errorMessage(): string | null {
    if (this.mustShowErrorMessage()) {
      return this.getErrorMessage();
    } else {
      return null;
    }
  }

  private mustShowErrorMessage(): boolean {
    return this.ctrl.invalid && this.ctrl.touched;
  }

  private getErrorMessage(): string | null {
    const error = this.ctrl.errors;

    const actualLength = error.minlength?.actualLength;
    const requiredMinLength = error.minlength?.requiredLength;
    const requiredMaxLength = error.maxlength?.requiredLength;

    if (error.required) {
      return 'Campo obrigatório';
    } else if (error.minlength) {
      return `Minímo de ${requiredMinLength} caracteres. Atual: ${actualLength}`;
    } else if (error.maxlength) {
      return `Máximo de ${requiredMaxLength} caracteres. Atual: ${actualLength}`;
    } else {
      return null;
    }
  }
}
