<div id="wrapper">
  <mat-drawer-container class="custom-drawer mat-typography" autosize>
    <mat-drawer
      id="sidebar"
      [class]="collapsedClass"
      #drawer
      mode="side"
      disableClose="true"
      opened="true"
    >
      <ul class="navigation" routerLinkActive="active">
        <div class="expandBtn">
          <i
            (click)="toggleExpanded()"
            [class]="icon"
            style="font-size: x-large"
            matTooltip="Diminuir/Expandir Menu"
            matTooltipPosition="right"
          ></i>
        </div>
        <li *ngFor="let menu of menuItems; index as i">
          <ng-container *ngIf="!menu.children">
            <a
              [routerLink]="menu.url"
              routerLinkActive="active"
              style="display: flex"
              [matTooltip]="menu.title"
              matTooltipPosition="right"
            >
              <mat-icon>{{ menu.icon }}</mat-icon>&nbsp;
              <span *ngIf="!isExpanded"> {{ menu.title }}</span>
            </a>
          </ng-container>
          <ng-container *ngIf="menu.children">
            <mat-accordion class="sidebar-accordion">
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
              >
                <mat-expansion-panel-header
                  [collapsedHeight]="customCollapsedHeight"
                  [expandedHeight]="customExpandedHeight"
                  [matTooltip]="menu.title"
                  matTooltipPosition="right"
                >
                  <mat-panel-title>
                    <mat-icon>{{ menu.icon }}</mat-icon>&nbsp;
                    <span *ngIf="!isExpanded">{{ menu.title }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <a
                  *ngFor="let child of menu.children"
                  class="sidebar-child"
                  [routerLink]="child.url"
                  routerLinkActive="active"
                  [matTooltip]="child.title"
                  matTooltipPosition="right"
                >
                  <div class="menu-icone">
                    <i [class]="child.icon"></i>
                  </div>
                  <span *ngIf="!isExpanded">{{ child.title }}</span>
                </a>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </li>
      </ul>
      <div class="sidebar-background"></div>
    </mat-drawer>
    <div class="sidebar-content" style="margin-top: 70px">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
