import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AvatarModule } from 'ngx-avatar';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';

import { MaterialModule } from './material.module';
import { environment } from 'env/environment';

import { LoadingComponent } from './components/loading/loading.component';
import { LoadingImageComponent } from './components/loading-image/loading-image.component';
import { CheckAuthComponent } from './components/check-auth/check-auth.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { ChartLineComponent } from './components/chart-line/chart-line.component';
import { TotalsCardComponent } from './components/totals-card/totals-card.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  useBothWheelAxes: true,
};

const SYSTEM = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule];

const COMPONENTS = [
  CheckAuthComponent,
  DashboardCardComponent,
  LoadingComponent,
  LoadingImageComponent,
  ChartLineComponent,
  TotalsCardComponent,
  PaginatorComponent,
  FormFieldErrorComponent,
  ModalConfirmationComponent,
  ModalContainerComponent,
];

const EXTERNAL = [
  AvatarModule,
  RecaptchaModule,
  NgxEchartsModule,
  ChartsModule,
  PerfectScrollbarModule,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...SYSTEM, ...EXTERNAL, MaterialModule],
  exports: [MaterialModule, ...SYSTEM, ...EXTERNAL, ...COMPONENTS],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaKey } as RecaptchaSettings,
    },
    ThemeService,
  ],
})
export class SharedModule {}
