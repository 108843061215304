import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss'],
})
export class ChartLineComponent implements OnInit {
  isLoading = true;
  showChart = false;

  @Input() data: any;
  @Input() title: string;

  lineChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      fontSize: 15,
      position: 'top',
      fullWidth: true,
    },
    legend: {
      position: 'bottom',
    },
  };
  lineChartLabelsX = [];
  lineChartType = 'line';
  lineChartLegend = false;
  lineChartData = [];
  lineChartColors: Color[] = [
    {
      backgroundColor: '#c41f1a20',
      borderColor: '#c41f1a60',
      pointBackgroundColor: '#c41f1a',
    },
  ];

  constructor() {}

  ngOnInit() {
    this.lineChartOptions.title.text = this.title;
    this.lineChartData = [{ data: this.data.qtdContratos }];
    this.lineChartLabelsX = this.data.dataExibicao;
  }
}
