import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { environment } from 'env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      url: `${environment.api}${req.url}`,
      headers: req.headers.set('Authorization', 'Bearer ' + sessionStorage['apiToken'] || ''),
      withCredentials: false,
    });
    if (req.headers.get('X-Skip-Interceptor') === 'true') {
      const apiReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + sessionStorage['apiToken'] || ''),
        withCredentials: false,
        responseType: 'json',
      });
      return next.handle(apiReq);
    } else {
      return next.handle(apiReq).pipe(
        catchError((error) => {
          console.error(error);
          this.handleAuthError(error);
          return of(error);
        }) as any,
      );
    }
  }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      console.error('handled error ' + error.status);
      this.router.navigate(['/login']);
      sessionStorage.clear();
      return of(error.message);
    } else if (error.status === 403) {
      this.router.navigate(['/login']);
    }
    throw error;
  }
}
